export default (() => {

  const heroSlider = document.querySelector('.hero-slider');

  // eslint-disable-next-line
  if(!heroSlider) return;
  const slides = heroSlider.querySelectorAll('.swiper-slide');
  const slidesCount = slides.length;
  const carousel = new Swiper(heroSlider.querySelector('.swiper-container'), {
    speed: 700,
    effect: 'fade',
    loop: slidesCount > 1,
    autoplay: {
        delay: 4000,
    },
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: heroSlider.querySelector('.btn-carousel-next'),
      prevEl: heroSlider.querySelector('.btn-carousel-prev')
    },
    pagination: {
      el: heroSlider.querySelector('.carousel-pagination'),
      type: 'bullets',
    },
  });
  carousel.init();

})();
