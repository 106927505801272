/* eslint-disable */
export default (() => {
  window.shopItem = () => {
    return {
      isOpened: false,
      mapInited: false,
      toggle(coords) {
        this.isOpened = !this.isOpened;
        if (!this.mapInited) {
          this.mapInited = true;
          this.mapInitialize(coords)
        }
      },
      close() {
        this.isOpened = false
      },
      mapInitialize(coords) {
        const mapId = this.$el.querySelector('[data-map]').getAttribute('id');
        const map = new ymaps.Map(mapId, {
          center: coords.split(','),
          zoom: 14,
          controls: []
        }, {
          searchControlProvider: 'yandex#search'
        });
        const myPlacemark = new ymaps.Placemark(map.getCenter(), {}, {
          iconLayout: 'default#image',
          iconImageHref: (process.env.NODE_ENV === 'production' ? '/themes/coffeeboom/src/build/images/shop-pin.svg' : 'images/shop-pin.svg'),
          iconImageSize: [48, 48],
          iconImageOffset: [-24, -24]
        })

        map.geoObjects.add(myPlacemark)
      }
    }
  }
})();
