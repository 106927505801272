import { pad } from '../utils';

export default (time, tick, cb) => {
  let counter = time || 60;
  let ticker;
  if (counter > 0) {
    ticker = setInterval(() => {
      counter -= 1;
      if (counter <= 0) {
        clearInterval(ticker);
        counter = 0;
        cb.call(this);
      }

      // Calculate remaining time
      let secs = counter;
      const mins  = Math.floor(secs / 60);
      secs -= mins * 60;
      tick.call(this, `${pad(mins, 2)}:${pad(secs, 2)}`)
    }, 1000);
  }
}
