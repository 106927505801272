 /* eslint-disable */
import { validate, validateAll } from 'indicative/validator'
import './utils';

export default (() => {
  window.adapterServerError = (jqXHR) => {
    const getInstancesAlpineValidation = () => {
      const xDataEl = document.querySelectorAll('[x-data]');
      let alpineInstances = [];
      Array.from(xDataEl).forEach((xData) => {
        const hasValidation = xData.getAttribute('x-data').includes('validate');
        if(hasValidation) {
          alpineInstances = [...alpineInstances, xData.__x.$data];
        }
      });
      return alpineInstances;
    }

    if(jqXHR.hasOwnProperty('responseJSON')) {
      let errorFields = jqXHR.responseJSON['X_OCTOBER_ERROR_FIELDS'];
      Object.keys(errorFields).forEach((field) => {
        let errorMessage = errorFields[field][0];
        let validateProxies = getInstancesAlpineValidation();
        validateProxies.forEach(proxy => {
          let fieldEl = proxy.$el.querySelector(`input[name="${field}"]`);
          if(fieldEl) {
            setTimeout(() => {
              proxy.setAsyncError(field, errorMessage);
            }, 100);
          }
        });
      });
    }
  };
  window.validate = () => {
    return {
      hasError: true,
      init(fieldsName) {
        this.form = fieldsName;
        this.checkValidate();
      },
      setAsyncError(name, error) {
        this.form[name].error = error;
      },
      validateField(e) {
        this.checkValidate();
        if(e.target.dirty)
          this.initiateValidationField(e.target);
        else
          this.initiateValidationField(e.target, true);
      },
      validateFieldBlur(e) {
        e.target.dirty = true;
        this.initiateValidationField(e.target);
      },
      checkValidate() {
        let {data, schema} = this.getValidationData(this.form);
        validateAll(data, schema)
        .then((r) => {
          this.hasError = false;
        })
        .catch((error) => {
          this.hasError = true;
        })
      },
      getValidationData(field) {
        let isField = typeof field === 'string';
        let data = {};
        let schema = {};
        let messages = {}
        if(isField) {
          data[field] = this.form[field].value;
          schema[field] = this.form[field].rules;

          if(this.form[field].messages) {
            messages[field] = this.form[field].messages;
          }
        } else {
          Object.keys(field).forEach((key) => {
            data[key] = this.form[key].value;
            schema[key] = this.form[key].rules;
          });
        }
        return {data, schema, messages};
      },
      initiateValidationField(field, silent = false) {
        let {data, schema} = this.getValidationData(field.name);
        validate(data, schema, this.form[field.name].messages).then(() => {
          if(!silent) {
            this.form[field.name].error = '';
          }
          field.valid = true;
        }).catch(error => {
          if(!silent) {
            this.form[field.name].error = error[0].message;
          }
          field.valid = false;
        })
      }
    }
  }
})();
