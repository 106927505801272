import 'alpinejs';
import 'bootstrap.native';
import alpineMixins from './alpine-mixins';
import sayHello from './lib/sayHello';
import './lib/custom-anchor-system';
import './components/forms';
import './validation/index';
import './components/hero-slider';
import './components/cart';
import './components/catalog-product';
import './components/cart-product';
import './lib/scroll-spy';
import './components/slider-reviews';
import './components/coffee-filter';
import './components/shop-item';
import './components/gallery';
import './components/modal'
import './components/scroll-bar'

window.alpineMixins = alpineMixins;
sayHello();
