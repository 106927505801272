import bsn from 'bootstrap.native';

export default (() => {

  document.addEventListener("DOMContentLoaded", ()=> {
    const myModal = document.getElementById('modalShow');
    // eslint-disable-next-line
    if(!myModal) return;
    const modalTime = myModal.getAttribute('data-time');
    const myModalInstance = new bsn.Modal(myModal,{backdrop: true});
    setTimeout(()=> {
      myModalInstance.show();
    }, modalTime*1000 );
  });
  const modal = document.getElementById('advertisingModal')
  // eslint-disable-next-line
  if(!modal) return;
  modal.addEventListener('show.bs.modal', (event)=>{
    // eslint-disable-next-line
    event.target.querySelector('[data-modal-text]').value = event.relatedTarget.closest('section').querySelector('.item-desc').innerText;
  }, false);
})();
