/* eslint-disable */

export default (() => {
  window.cartProduct = () => {
    return {
      id: null,
      qty: 0,
      isLoading: false,
      inc() {
        this.isLoading = true
        $.request('Cart::onAdd', {
          data: {
            'cart': [{'offer_id': this.id, 'quantity': this.qty + 1}]
          },
          update: {
            'cart/cart-list': '.cart-list',
            'cart/shipping-list': '.shipping-list-container',
            'cart/form-submit': '.form-submit-container',
          },
          complete() {
            this.isLoading = false
          }
        });
      },
      dec() {
        if(this.qty === 1) return;
        const cart = this.getCart();
        const item = cart.find((cartItem) => cartItem.offer_id === this.id);
        item.quantity--;
        this.isLoading = true;
        $.request('Cart::onUpdate', {
          'data': {
            cart
          },
          update: {
            'cart/cart-list': '.cart-list',
            'cart/shipping-list': '.shipping-list-container',
            'cart/form-submit': '.form-submit-container',
          },
          complete() {
            this.isLoading = false
          }
        });
      },
      getCart() {
        const json = document.querySelector('.cart-block').dataset.offerList;
        return JSON.parse(json);
      },
      remove() {
        this.isLoading = true
        $.request('Cart::onRemove', {
          data: {
            'cart': [this.id]
          },
          update: {
            'cart/cart-list': '.cart-list',
            'cart/shipping-list': '.shipping-list-container',
            'cart/form-submit': '.form-submit-container',
          },
          complete() {
            this.isLoading = false
          }
        });
      }
    }
  }
})();
