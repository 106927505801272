export default (() => {

  const section = document.querySelector('.section-reviews');

  // eslint-disable-next-line
  if(!section) return;
  const carousel = new Swiper(section.querySelector('.swiper-container'), {
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      nextEl: section.querySelector('.btn-carousel-next'),
      prevEl: section.querySelector('.btn-carousel-prev')
    },
    breakpoints: {
      480: {
        slidesPerView: 'auto',
        spaceBetween: 128,
      },
    }
  });
  carousel.init();

})();
