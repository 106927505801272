export default (() => {

  const catalog = document.querySelector('.catalog-wrap');

  if(!catalog) return;

  const sections = document.querySelectorAll(".catalog-section");
  const menuLinks = document.querySelectorAll("#_js_catalog-nav a");
  const makeActive = (link) => {if(menuLinks[link]) menuLinks[link].closest('li').classList.add("active")};
  const removeActive = (link) => {;menuLinks[link].closest('li').classList.remove("active")};
  const removeAllActive = () => [...Array(sections.length).keys()].forEach((link) => removeActive(link));
  const sectionMargin = 200;
  let currentActive = 0;
  window.addEventListener("scroll", () => {
    const current = sections.length - [...sections].reverse().findIndex((section) => window.scrollY >= section.offsetTop - sectionMargin ) - 1
    if (current !== currentActive) {
      removeAllActive();
      currentActive = current;
      makeActive(current);
    }

    const offsetTopCatalog = catalog.getBoundingClientRect().top;
    const header = document.querySelector('.header');
    // const scrollTop = window.pageYOffset || document.body.scrollTop;
    const offset = 90;
    if(offsetTopCatalog - offset <= 0) {
      header.classList.add('anchors-visible')
    } else {
      header.classList.remove('anchors-visible')
    }
  });
})();
