import GLightbox from 'glightbox'
/* eslint-disable */
export default (() => {
  window.lightbox = () => {
    return {
      gallery: null,
      init(elements) {
        this.gallery = GLightbox({
          elements,
          autoplayVideos: true,
        });
      },
      openGallery() {
        this.gallery.open();
      }
    }
  }
})();
