function sayHello() {
  if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      const args = ['\n %c Made with ❤️ by Amphibia %c http://www.amphibia.kz %c %c \n', 'border: 1px solid #284ECB;color: #fff; background: #284ECB; padding:5px 0;', 'color: #fff; padding:5px 0;border: 1px solid #284ECB;', 'padding:5px 0;', 'color: #b0976d; padding:5px 0;'];
      window.console.log.apply(console, args);
  } else if (window.console) {
      window.console.log('Made with love ❤️ Amphibia.kz - http://www.amphibia.kz/  ❤️');
  }
};

module.exports = sayHello;
