import axios from 'axios';
import API from '../lib/api';
import countdownTimer from './countdown-timer';

export default (() => {
  window.cart = () => {
    return {
      codeSended: false,
      timerIsRunning: false,
      count: '00:00',
      phoneValid: false,
      sendCode(phone) {
        this.codeSended = true;
        this.startTimer();
        const phoneFormatted = phone.replace(/[^0-9]/g, '');
        axios.get(`${API.SEND_CODE}${phoneFormatted}`);
      },
      startTimer() {
        this.timerIsRunning = true;
        countdownTimer(50, (n) => {
          this.count = n;
        }, () => {
          this.timerIsRunning = false;
        })
      },
      initCart() {
        this.$refs.cartPhone.imask.on('accept', () => {
          this.phoneValid = false
        }).on('complete', () => {
          this.phoneValid = true
        });
      }
    }
  }
})();
