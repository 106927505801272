/* eslint-disable */

import '@ungap/global-this'
import IMask from 'imask';
import Choices from 'choices.js';

export default (() => {

  const phonesFields          = document.querySelectorAll('input[data-mask^="phone"]');
  const customFieldsMask      = document.querySelectorAll('input[data-mask^="custom"]');
  const selects               = document.querySelectorAll('.ui-select');

  Array.from(phonesFields).forEach((field) => {
    const customMask = field.getAttribute('data-mask').split('|')[1]
    const mask = customMask || '+7(000)000-00-00'
    field.imask = IMask(field, {
      mask,
      lazy: false
    });
    field.addEventListener('paste', (e) => {
  		e.preventDefault();
  		let clipboardCurrentData = (e.originalEvent || e).clipboardData.getData('text/plain').replace(/[^0-9]/g, "").split('');

  		if(clipboardCurrentData.length >= 11)
  			clipboardCurrentData = clipboardCurrentData.slice(1, clipboardCurrentData.length)

  		window.document.execCommand('insertText', false, clipboardCurrentData.join(''));
  	});
  });

  Array.from(customFieldsMask).forEach((field) => {
    const mask = field.getAttribute('data-mask').split('|')[1];
    if(!mask){
      console.error(`Не указана маска у поля ${field.name}`)
      return
    }
    field.imask = IMask(field, {
      mask
    });
  })

  Array.from(selects).forEach((item) => {
    const select = new Choices(item, {
      placeholder: true,
      searchEnabled: false,
      shouldSort: false
    });
  });

})();
