export default (() => {
  window.catalogProduct = () => {
    return {
      selectedId: null,
      offers: [],
      qty: 0,
      isLoading: false,
      init(offers) {
        this.offers = offers;
        this.selectedId = offers[0].id;
      },
      inc() {
        const that = this;
        that.isLoading = true;
        $.request('Cart::onAdd', {
          data: {
            'cart': [{'offer_id': that.getOffer().id, 'quantity': that.getOffer().qty + 1}]
          },
          update: {'cart/cart-info': '.cart-info'},
          complete() {
            that.isLoading = false;
            that.setQty(that.getOffer().qty + 1);
          }
        });
      },
      dec() {
        const that = this;
        that.isLoading = true;
        if(that.getOffer().qty === 1) {
          $.request('Cart::onRemove', {
            'data': {
              cart: [that.getOffer().id]
            },
            update: {'cart/cart-info': '.cart-info'},
            complete() {
              that.isLoading = false;
              that.setQty(that.getOffer().qty - 1);
            }
          });
          return;
        };
        const cart = this.getCart();
        const indexPositionInCart = cart.findIndex((item) => item.offer_id === that.getOffer().id);
        // eslint-disable-next-line
        cart[indexPositionInCart].quantity --;
        $.request('Cart::onUpdate', {
          'data': {
            cart
          },
          update: {'cart/cart-info': '.cart-info'},
          complete() {
            that.isLoading = false;
            that.setQty(that.getOffer().qty - 1);
          }
        });
      },
      addCart() {
        const that = this;
        that.isLoading = true;
        $.request('Cart::onAdd', {
          data: {
            'cart': [{'offer_id': that.getOffer().id, 'quantity': 1}]
          },
          update: {'cart/cart-info': '.cart-info'},
          complete() {
            that.isLoading = false;
            that.setQty(1)
          }
        });
      },
      getOffer() {
        return this.offers.find((offer) => +offer.id === +this.selectedId);
      },
      getCart() {
        const json = document.querySelector('.cart-info-wrap').dataset.offerList;
        return JSON.parse(json);
      },
      setQty(qty) {
        const offer = this.getOffer();
        offer.qty = qty;
      }
    }
  }
})();
