/* eslint-disable */
import Shuffle from 'shufflejs/dist/shuffle.esm';

export default (() => {
  window.coffeeFilter = () => {
    return {
      init() {
        this.shuffleInit();
      },
      setFilter(type,value) {
        let types = document.querySelector('.accordion-container') ? ['city'] : ['city', 'format'];
        this[type] = value;
        let selectGroups = [];
        for(let i = 0; i < types.length; i++) {
          if(this[types[i]] !== 'all') selectGroups.push(this[types[i]]);
        }
        window.shuffleShop.filter((item) => {
          let group = JSON.parse(item.getAttribute('data-groups'));
          let isFinding = true;
          for(let i = 0; i < selectGroups.length; i++) {
            if(!group.includes(selectGroups[i])) isFinding = false
          }
          return isFinding
        });
      },
      shuffleInit() {
        const container = document.querySelector('.coffee-shops');
        const containerAccordion = document.querySelector('.accordion-container');
        if(container) {
          window.shuffleShop = new Shuffle(container, {
            itemSelector: '.coffee-house-item',
            // gutterWidth: 24
          });
        }
        if(containerAccordion) {
          window.shuffleShop = new Shuffle(containerAccordion, {
            itemSelector: '.item-accordion',
            // gutterWidth: 24
          });
        }
      }
    }
  }
})();
